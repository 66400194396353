<template>
  <div id="item-detail-page">
    <vs-popup classContent="popup-example" :title="$t('discount.add')" :active.sync="showModal">
      <div class="grid grid-cols-2 gap-4">
        <v-select class="col-span-2" v-validate="'required'" :options="depTypes" :label="`name_${$i18n.locale}`" name="depType" :placeholder="$t('discount.selectDep')" v-model="discount.dep_type" :reduce="opt => opt.value" />
        <span class="text-danger text-sm" v-show="errors.has('depType')">{{ errors.first('depType') }}</span>
        
        <flat-pickr v-validate="'required'" v-model="discount.from" :config="configdateTimePicker" class="w-full inputx mt-3" name="from" :placeholder="$t('discount.selectStartDate')" />
        <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
        <flat-pickr v-validate="'required'" v-model="discount.to" :config="configdateTimePicker" class="w-full inputx mt-3" name="to" :placeholder="$t('discount.selectEndDate')" />
        <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
        
        <v-select class="mt-3" v-validate="'required'" :options="types" :label="`name_${$i18n.locale}`" name="type" :placeholder="$t('discount.type')" v-model="discount.type" :reduce="opt => opt.value" />
        <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        <vs-input v-if="discount.type !== 'bonused'" v-validate="'required'" class="w-full inputx mt-3 mb-1" name="amount" :placeholder="$t('discount.amount')" v-model="discount.amount" />
        <div v-else class="vx-row px-4">
          <vs-input v-validate="'required'" name="required" class="w-1/2 inputx pr-2 mt-3 mb-1" :placeholder="$t('discount.required')" v-model="discount.required" />
          <vs-input v-validate="'required'" name="bonus" class="w-1/2 inputx pl-2 mt-3 mb-1" :placeholder="$t('discount.bonus')" v-model="discount.bonus" />
        </div>
        <span class="text-danger text-sm" v-show="errors.has('required')">{{ errors.first('required') }}</span>
        <span class="text-danger text-sm" v-show="errors.has('bonus')">{{ errors.first('bonus') }}</span>
        <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>

        <!-- <vs-input class="w-full inputx mt-3" placeholder="Disabled" v-model="value2" /> -->
        <!-- <vs-textarea class="col-span-2" placeholder="Bellik"></vs-textarea> -->
      </div>
      <div class="flex mt-5">
        <vs-button @click="addDiscount(product.id)" color="primary" class="mt-5 ml-auto" type="filled">{{ $t('submit') }}</vs-button>
      </div>

      <!-- <vs-popup title="Inner popup" :active.sync="popupActive3">
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </vs-popup> -->
    </vs-popup>
    <vs-alert color="danger" title="Error Fetching Product Data" :active.sync="error_occured">
      <span>{{ error_msg }}. </span>
      <span>
        <span>Check </span><router-link :to="{name:'products'}" class="text-inherit underline">All Items</router-link>
      </span>
    </vs-alert>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-alert color="danger" :active.sync="isActive">
        <span>{{ $t('product') }} {{ $t('isNotActive') }}. </span>
      </vs-alert>
      <vx-card class="mt-6">
        <vs-row class="mb-3">
          <h3 class="mr-auto">{{ product[`name_${$i18n.locale}`] }}</h3>
          <template v-if="$acl.check('admin')">
            <input type="file" class="hidden" ref="uploadSliderImg" @change="uploadNewImage($event)" accept="image/*">
            <div class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="$refs.uploadSliderImg.click()">
              <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('add_image') }}</span>
            </div>
            <template v-if="product.isActive">
              <div v-if="!product.discount" class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="showModal = true">
                <feather-icon icon="PercentIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base">{{ $t('discount.add') }}</span>
              </div>
            </template>
            <div v-if="product.discount" class="btn-delete p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteDiscount(product.discount.uuid)">
              <feather-icon icon="PercentIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('discount.delete') }}</span>
            </div>
            <router-link :to="{name: 'productEdit', params: {id: $route.params.id}}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
            </router-link>
            <div v-if="!product.isActive" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(product.uuid)">
              <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">{{ $t('delete') }}</span>
            </div>
          </template>
        </vs-row>
        <vs-table stripe :data="Object.keys(product)">
          <template slot="thead">
            <vs-th>{{$t('property')}}</vs-th>
            <vs-th>{{$t('value')}}</vs-th>
          </template>
          <template>
            <vs-tr class="w-full">
              <vs-td class="">
                <span class="uppercase font-medium">{{ $t('props.images') }}</span>
              </vs-td>
              <vs-td class="">
                <div class="flex flex-row overflow-x-auto" style="width: 100%;">
                  <div v-for="img in product.product_images" :key="img.id" class="img-container mr-3 relative p-4">
                    <img :src="`${$baseUrl}/products/preview/${img.uuid}.webp`" alt="" class="h-40">
                    <vs-button v-if="product.product_images.length > 1 && $acl.check('admin')" color="danger" class="px-3 py-2 absolute top-0 right-0" @click="removeCurrImg(img.uuid)">
                      <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
                    </vs-button>
                  </div>
                </div>
              </vs-td>
            </vs-tr>
            <vs-tr v-for="(prop, idx) in props" :key="idx">
              <template>
                <template v-if="(prop === 'price_bulk' || prop === 'bulk_min')">
                  <vs-td v-if="product.isBulk">
                    <span class="uppercase font-medium">{{$t(`props.${prop}`)}}</span>
                  </vs-td>
                </template>
                <vs-td v-else>
                  <span class="uppercase font-medium">{{$t(`props.${prop}`)}}</span>
                </vs-td>
                <vs-td v-if="product[prop] === true || product[prop] === false">
                  <vs-chip :color="product[prop] ? 'success' : 'danger'">
                    <feather-icon v-if="product[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                    <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                  </vs-chip>
                </vs-td>
                <vs-td v-else-if="prop === 'brand'">
                  <router-link v-if="product.brand" :to="`/brands/${product[prop].uuid}`" class="font-medium">{{ product[prop][`name_${$i18n.locale}`] }}</router-link>
                  <span v-else>---</span>
                </vs-td>
                <vs-td v-else-if="prop === 'categories'">
                  <router-link v-for="category in product[prop]" :key="category.id" :to="`/categories/${category.uuid}`" class="font-medium">{{ category[`name_${$i18n.locale}`] }}, </router-link>
                </vs-td>
                <vs-td v-else-if="prop === 'discount' && product.discount">
                  <p class="font-medium">
                    <vs-chip color="danger">
                      {{ product.discount.dep_type | capitalize }} {{$t('discount.d')}}
                    </vs-chip>
                    <vs-chip color="danger mx-3">
                      <span v-if="product.discount.type === 'bonused'">{{ product.discount.required }} + {{ product.discount.bonus }}</span>
                      <span v-else-if="product.discount.type === 'percentage'">-{{ product.discount.amount }}%</span>
                      <span v-else>-{{ product.discount.amount }}TMT</span>
                    </vs-chip>
                  </p>
                  <div class="font-medium">
                    {{ new Date(product.discount.from).toLocaleString('ru-RU') }} - {{ new Date(product.discount.to).toLocaleString('ru-RU') }}
                  </div>
                </vs-td>
                <vs-td v-else-if="prop === 'likes' && product[prop]">
                  {{ product[prop].length }}
                </vs-td>
                <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt'">
                  {{ new Date(product[prop]).toLocaleString('ru-RU') }}
                </vs-td>
                <vs-td v-else-if="prop === 'type'" class="font-medium">
                  {{ product[prop] | capitalize }}
                </vs-td>
                <template v-else-if="(prop === 'price_bulk' || prop === 'bulk_min')">
                  <vs-td v-if="product.isBulk" class="font-medium">
                    {{ product[prop] }}
                  </vs-td>
                </template>
                <vs-td v-else>
                  {{ product[prop] }}
                </vs-td>
              </template>
            </vs-tr>
          </template>
        </vs-table>

      </vx-card>
    </template>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default{
  components: {
    StarRating,
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select')
  },
  data () {
    return {
      configdateTimePicker: { enableTime: true, time_24hr: true, locale: Russian },
      showModal: false,
      isLoading: true,
      error_occured: false,
      error_msg: '',
      product: {},
      newImg: null,
      types: [
        {value: 'percentage', name_en: 'Percentage', name_tm: 'Protsent', name_ru: 'Процент'},
        {value: 'price', name_en: 'Fix Price', name_tm: 'Kesgitli baha', name_ru: 'Стабильная стоимость'},
        {value: 'bonused', name_en: 'Bonus', name_tm: 'Bonus', name_ru: 'Бонусы'}
      ],
      mainDepTypes: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      discount: {
        from: null,
        to: null,
        type: null,
        dep_type: null,
        amount: null,
        required: null,
        bonus: null
      }
    }
  },
  computed: {
    isActive () { return !this.product.isActive },
    props () { return Object.keys(this.product).filter((el) => el !== 'product_images' && el !== 'brandId') },
    depTypes () { return this.product.type === 'both' ? this.mainDepTypes : this.mainDepTypes.filter((el) => el.value === this.product.type) }
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/products/${this.$route.params.id}`).then(response => {
        this.product = response.data
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.error_occured = true
        this.error_msg = error.message
      })
    },
    deleteData (id) {
      this.$http.delete(`/products/delete/${id}`).then(() => {
        this.notify('success', 'Product successfully deleted!')
        this.$router.push('/products')
      }).catch(err => {
        this.notify('error', err.message)
      })
    },
    uploadNewImage (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.newImg = e.target.result
          this.$http.post(`/products/upload-image/${this.product.uuid}`, {
            photo: this.newImg
          }).then(() => {
            this.notify('success', 'Product image successfully uploaded!')
            this.newImg = null
            this.fetchData()
          }).catch(err => {
            this.notify('error', err.message)
            this.newImg = null
          })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    async removeCurrImg (id) {
      await this.$http.delete(`/products/delete-image/${id}`).then(() => {
        this.notify('success', 'Product image successfully deleted!')
        this.fetchData()
      }).catch(err => { this.notify('error', err.message) })
    },
    addDiscount (id) {
      this.discount.from = new Date(this.discount.from)
      this.discount.to = new Date(this.discount.to)
      // this.discount.from.setHours(this.discount.from.getHours() - 5)
      // this.discount.to.setHours(this.discount.to.getHours() - 5)
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/products/discount/add', {
            ...this.discount,
            productId: id
          }).then(response => {
            if (response.status === 201) {
              this.notify('success', 'Discount successfully created!')
              this.showModal = false
              this.fetchData()
            }
          }).catch(err => { this.notify('error', err.message) })
        } else {
          // form have errors
        }
      })
    },
    updateDiscount (uuid) {
      this.$http.patch(`/products/discount/edit/${uuid}`).then(response => {
        if (response.status === 204) {
          this.notify('success', 'Discount successfully deleted!')
          this.showModal = false
          this.fetchData()
        }
      }).catch(err => { this.notify('error', err.message) })
    },
    deleteDiscount (uuid) {
      this.$http.delete(`/products/discount/delete/${uuid}`).then(response => {
        if (response.status === 200) {
          this.notify('success', 'Discount successfully deleted!')
          this.showModal = false
          this.fetchData()
        }
      }).catch(err => { this.notify('error', err.message) })
    },
    notify (status, msg) {
      if (status === 'success') {
        this.$vs.notify({
          title: this.$t('notify.success'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      } else {
        this.$vs.notify({
          title: this.$t('notify.error'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  },
  async created () {
    await this.fetchData()
  }
}
</script>

<style lang="scss">

@import "@/assets/scss/vuexy/_variables.scss";

#item-detail-page {
  .color-radio {
    height: 2.28rem;
    width: 2.28rem;
    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    background-color: #f7f7f7;

    .theme-dark & {
      background-color: $theme-dark-secondary-bg;
    }
  }

  .product-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-product-swiper {
      // padding-right: 2rem;
      // padding-left: 2rem;

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0,0,0,0.1), 0 5px 12px 0 rgba(0,0,0,0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}
</style>
